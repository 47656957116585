<template>
  <div>
    <b-modal ref="dialog" size="lg" @hidden="fieldModalClose" hide-footer title="作成">
      <b-row>
        <b-col>
          <b-form-group label="畑名" label-for="input-field-name">
            <b-form-input type="text" v-model="field.name" required :state="fieldNameStatus"></b-form-input>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-form-group label="都道府県">
            <b-form-input type="text" v-model="field.prefecture"></b-form-input>
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group label="区市町村">
            <b-form-input type="text" v-model="field.city"></b-form-input>
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group label="番地">
            <b-form-input type="text" v-model="field.address"></b-form-input>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-form-group label="緯度">
            <b-form-input type="text" v-model="field.lat"></b-form-input>
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group label="経度">
            <b-form-input type="text" v-model="field.lon"></b-form-input>
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group>
            <b-button
              type="submit"
              size="sm"
              variant="primary"
              v-on:click="currentPosition()"
              style="position: absolute; top: 30px;"
            >
              <i class="fa fa-dot-circle-o"></i> 現在位置取得
            </b-button>
            <p>{{this.currentPosStatus}}</p>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col class="text-right">
          <b-button type="submit" size="sm" variant="primary" v-on:click="showSectionDialog()">
            次へ
            <i class="fa fa-chevron-right" />
          </b-button>
        </b-col>
      </b-row>
    </b-modal>
    <b-modal ref="sectionDialog" size="lg" @hidden="sectionModalClose" hide-footer title="作成">
      <div v-for="(item, index) in this.field.sections" :key="item.name" :value="item">
        <b-row>
          <b-col md="9">
            <b-form-group :label="'区画名'+(index+1)" label-for="input-section">
              <b-row>
                <b-col>
                  <b-form-input id="input-section" type="text" v-model="field.sections[index].name"></b-form-input>
                </b-col>
                <b-col>
                  <b-button size="sm" variant="danger" pill v-on:click="sectionDelete(index)">
                    <i class="fa fa-minus" />
                  </b-button>
                </b-col>
              </b-row>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="8" offset-md="1">
            <div v-for="(rowItem, rowIndex) in item.rows" :key="rowItem.name" :value="rowItem">
              <b-form-group :label="'畝名'+(rowIndex+1)" label-for="input-row">
                <b-row>
                  <b-col>
                    <b-form-input
                      id="input-row"
                      type="text"
                      v-model="field.sections[index].rows[rowIndex].name"
                    >{{(rowIndex+1)}}</b-form-input>
                  </b-col>
                  <b-col>
                    <b-button
                      size="sm"
                      variant="danger"
                      pill
                      v-on:click="rowDelete(index,rowIndex)"
                    >
                      <i class="fa fa-minus" />
                    </b-button>
                  </b-col>
                </b-row>
              </b-form-group>
            </div>
          </b-col>
        </b-row>
        <b-row>
          <b-col offset-md="2">
            <b-button
              size="sm"
              variant="primary"
              class="fa fa-plus my-1 mr-1"
              pill
              v-on:click="rowAdd(index)"
            ></b-button>畝の追加
          </b-col>
        </b-row>
      </div>
      <b-row>
        <b-col>
          <b-form-group>
            <b-button
              type="submit"
              size="sm"
              variant="primary"
              class="fa fa-plus my-1 mr-1"
              pill
              v-on:click="sectionAdd()"
            ></b-button>区画追加
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col class="text-right">
          <b-button
            type="submit"
            size="sm"
            variant="primary"
            class="fa fa-chevron-left mx-1"
            v-on:click="hideSectionDialog()"
          >戻る</b-button>
          <b-button
            type="submit"
            size="sm"
            variant="primary"
            class="fa fa-dot-circle-o mx-1"
            v-on:click="createField()"
          >作成</b-button>
        </b-col>
      </b-row>
    </b-modal>
  </div>
</template>

<script>
import { CREATE_FIELD } from "../../components/field-mutation";
import { getPosErrorMsg } from "../../components/message";

const ADD_NUM = 1;
export default {
  components: {},
  data() {
    return {
      field: {
        farmerId: "RmFybWVyTm9kZTox",
        name: null,
        prefecture: null,
        city: null,
        address: null,
        lat: null,
        lon: null,
        sections: []
      },
      fieldNameStatus: null,
      status: null
    };
  },
  props: {},
  watch: {},
  computed: {
    currentPosStatus: function() {
      return this.status;
    }
  },
  methods: {
    init: function() {},
    fieldModalClose: function() {
      this.field = {
        farmerId: "RmFybWVyTm9kZTox",
        name: null,
        prefecture: null,
        city: null,
        address: null,
        lat: null,
        lon: null,
        sections: []
      };
    },
    sectionModalClose: function() {
      this.field.sections = [];
    },
    currentPosition: function() {
      let _this = this;
      this.status = "取得中...";
      navigator.geolocation.getCurrentPosition(
        function(position) {
          _this.field.lat = position.coords.latitude;
          _this.field.lon = position.coords.longitude;
          _this.status = null;
        },
        function(error) {
          _this.status = null;
          switch (error.code) {
            case 1:
              alert(getPosErrorMsg.PERMISSION);
              break;
            case 2:
              alert(getPosErrorMsg.FETCH);
              break;
            case 3:
              alert(getPosErrorMsg.TIME_OUT);
              break;
            default:
              alert(getPosErrorMsg.OTHER + error.code);
              break;
          }
        }
      );
    },
    sectionAdd: function() {
      let length = this.field.sections.length + ADD_NUM;
      this.field.sections.push({ name: length, rows: [] });
    },
    rowAdd: function(index) {
      let length = this.field.sections[index].rows.length + ADD_NUM;
      this.field.sections[index].rows.push({ name: length });
    },
    sectionDelete: function(index) {
      this.field.sections.splice(index, 1);
    },
    rowDelete: function(sectionIndex, rowIndex) {
      this.field.sections[sectionIndex].rows.splice(rowIndex, 1);
    },
    // --------------------------------
    // modalを開く
    // --------------------------------
    showDialog: function() {
      this.$refs["dialog"].show();
    },
    hideDialog: function() {
      this.$refs["dialog"].hide();
    },
    showSectionDialog: function() {
      if (!this.field.name) {
        this.fieldNameStatus = false;
        return;
      }
      this.fieldNameStatus = null;
      this.$refs["sectionDialog"].show();
    },
    hideSectionDialog: function() {
      this.$refs["sectionDialog"].hide();
    },
    createField: function() {
      this.$apollo
        .mutate({
          mutation: CREATE_FIELD,
          variables: {
            input: this.field
          }
        })
        .then(data => {
          this.$emit("refetch");
          this.$refs["dialog"].hide();
          this.$refs["sectionDialog"].hide();
        })
        .catch(error => {
          console.error(error);
          alert("登録エラー");
        });
    }
  }
};
</script>
