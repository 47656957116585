<template>
  <div>
    <b-card>
      <b-row>
        <b-col>
          <h3>畑マスタ 編集</h3>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <field-list />
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import FieldList from "../components/FieldList";

const DEFAULT_INDEX = 0;

export default {
  components: {
    FieldList
  },
  data() {
    return {};
  }
};
</script>
