<template>
  <div>
    <field-create-modal ref="fieldCreateModal" @refetch="fieldRefetch" />
    <field-update-modal :propField="field" ref="fieldUpdateModal" @refetch="fieldRefetch" />
    <field-delete-modal ref="fieldDeleteModal" :fieldId="fieldId" @refetch="fieldRefetch" />
    <div v-if="$apollo.loading">Loading...</div>
    <div v-else>
      <b-table responsive :fields="list" :items="this.fields.edges">
        <template v-slot:cell(name)="data">
          <td>{{data.item.node.name}}</td>
        </template>
        <template v-slot:cell(address)="data">
          <td>{{adjustAddress([data.item.node.prefecture, data.item.node.city, data.item.node.address])}}</td>
        </template>
        <template v-slot:cell(lat)="data">
          <td>{{data.item.node.lat}}</td>
        </template>
        <template v-slot:cell(lon)="data">
          <td>{{data.item.node.lon}}</td>
        </template>
        <template v-slot:cell(update)="data">
          <b-button
            type="submit"
            size="sm"
            variant="primary"
            class="icon-pencil"
            v-on:click="showUpdateDialog(data.item.node)"
          ></b-button>
        </template>
        <template v-slot:cell(delete)="data">
          <b-button
            type="submit"
            size="sm"
            variant="danger"
            class="icon-trash"
            v-on:click="showDeleteDialog(data.item.node.id)"
          ></b-button>
        </template>
      </b-table>
      <b-form-group>
        <b-button
          type="submit"
          size="sm"
          class="icon-plus"
          variant="outline-primary"
          v-on:click="showCreateDialog()"
        >新規作成</b-button>
      </b-form-group>
    </div>
  </div>
</template>

<script>
import FieldCreateModal from "./FieldCreateModal";
import FieldUpdateModal from "./FieldUpdateModal";
import FieldDeleteModal from "./FieldDeleteModal";
import { FIELD_QUERY } from "../../components/field-query";
import { nullCheckConvert } from "../../components/null-check";

const DEFAULT_FARMER_ID = "RmFybWVyTm9kZTox";
export default {
  components: {
    FieldCreateModal,
    FieldUpdateModal,
    FieldDeleteModal
  },
  data() {
    return {
      list: [
        { key: "name", label: "名前" },
        { key: "address", label: "住所" },
        { key: "lat", label: "緯度" },
        { key: "lon", label: "経度" },
        { key: "update", label: "編集" },
        { key: "delete", label: "削除" }
      ],
      field: null,
      fields: [],
      fieldId: null
    };
  },
  apollo: {
    fields: {
      query: FIELD_QUERY,
      variables: {
        farmerId: DEFAULT_FARMER_ID
      },
      result({ data, loading }) {},
      error(error) {
        console.error("field", error);
      }
    }
  },
  methods: {
    adjustAddress: function(str) {
      let address = "";
      for (let val in str) {
        if (str[val]) {
          address += str[val];
        } else {
          address += "";
        }
      }

      if (address.length) {
        return address;
      } else {
        return "―";
      }
    },
    // --------------------------------
    // modalを表示
    // --------------------------------
    showCreateDialog: function() {
      this.$refs.fieldCreateModal.showDialog();
    },
    // --------------------------------
    // modalを表示
    // --------------------------------
    showUpdateDialog: function(field) {
      this.field = field;
      this.$refs.fieldUpdateModal.showDialog();
    },
    // --------------------------------
    // modalを表示
    // --------------------------------
    showDeleteDialog: function(id) {
      this.fieldId = id;
      this.$refs.fieldDeleteModal.showDialog();
    },
    // --------------------------------
    // 畑情報の再取得
    // --------------------------------
    fieldRefetch: function() {
      this.$apollo.queries.fields.refetch();
    }
  }
};
</script>
