<template>
  <div>
    <b-modal ref="dialog" @ok="deleteField">削除してもよろしいですか</b-modal>
  </div>
</template>

<script>
import { DELETE_FIELD } from "../../components/field-mutation";

export default {
  props: ["fieldId"],
  methods: {
    showDialog: function() {
      this.$refs["dialog"].show();
    },
    // --------------------------------
    // 削除
    // --------------------------------
    deleteField: function() {
      this.$apollo
        .mutate({
          mutation: DELETE_FIELD,
          variables: {
            id: this.fieldId
          }
        })
        .then(data => {
          this.$emit("refetch");
        })
        .catch(error => {
          console.error(error);
          alert(error);
        });
    }
  }
};
</script>
