import gql from "graphql-tag";

export const CREATE_FIELD = gql`
  mutation createField($input:FieldInput!){
    createField(input:$input){
      field{
        id
        name
        sectionsSet{
          edges{
            node{
              id
              name
              rowsSet{
                edges{
                  node{
                    id
                    name
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;


export const UPDATE_FIELD = gql`
  mutation updateField($id:ID!,$input:FieldInput!,$rowId:[ID],$sectionId:[ID]){
    updateField(id:$id,input:$input,rowId:$rowId,sectionId:$sectionId){
      field{
        id
        name
        sectionsSet{
          edges{
            node{
              id
              name
              rowsSet{
                edges{
                  node{
                    id
                    name
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;


export const DELETE_FIELD = gql`
  mutation deleteField($id:ID){
    deleteField(id:$id){
      field{
        id
        name
        sectionsSet{
          edges{
            node{
              id
              name
              rowsSet{
                edges{
                  node{
                    id
                    name
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
